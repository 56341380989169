import React, { lazy } from 'react'
import '../src/sass/main.scss'
const Navbar = lazy(() => import('./components/Navbar'))
const Home = lazy(() => import('./components/Home'))
const Services = lazy(() => import('./components/Services'))
const Gallery = lazy(() => import('./components/Gallery'))
const Contact = lazy(() => import('./components/Contact'))
const Footer = lazy(() => import('./components/Footer'))

function App () {
  return (
    <div className='h-screen justify-between antialiased text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-800'>
      <Navbar />
      <Home />
      <Services />
      <Gallery />
      <Contact />
      <Footer />
    </div>
  )
}

export default App
